import {ecomAppDefID} from '../constants';

export async function reinstallPage(sdk: IEditorSdk, appToken: string, pageId: string, action: Function) {
  const pagesData = await sdk.pages.data.getAll();
  const pageToRemove = pagesData.find((page) => page.tpaPageId === pageId);
  if (!pageToRemove) {
    return;
  }

  const pageRef = {id: pageToRemove.id, type: pageToRemove.type};
  await sdk.pages.data.update(appToken, {
    pageRef,
    data: {managingAppDefId: ecomAppDefID},
  });
  await sdk.pages.remove(appToken, {pageRef, shouldShowEditorRemovePanel: false});
  await action();
}
