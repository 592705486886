import {ecomAppDefID} from '../constants';

async function getRef(sdk: IEditorSdk, appToken: string) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId(appToken, ecomAppDefID)).applicationId;
  const ecomComps = await (sdk.document.tpa as any).app.getAllCompsByApplicationId(appToken, tpaApplicationId);
  return ecomComps?.[0].id;
}

export async function freeProductsLegacyFlag(sdk: IEditorSdk, appToken: string, allowFreeProducts: boolean = false) {
  const compId = await getRef(sdk, appToken);

  if (!compId) {
    throw new Error('cannot find a compId for freeProductsLegacyFlag');
  }

  await (sdk.document.tpa as any).data.set(appToken, {
    compRef: {id: compId, type: 'DESKTOP'},
    key: 'ALLOW_FREE_PRODUCTS',
    value: allowFreeProducts,
    scope: 'APP',
  });
}
